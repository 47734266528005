import {TEMPLATE} from '@/plugins/firebase/type'

export default class Channel {
    constructor(options = {}) {
        Object.assign(this, {
            vmd: [], // 2021-11-02, vmd object list로 변경
            name: '',
            no: 0, // channel no
            creationDateTime: '',
            lastModifiedDateTime: '',
            templateType: TEMPLATE.HOT_DEAL.code,
        }, options);
        Object.assign(this, options);
    }

    add(hash) {
        let find = this.vmd.filter(x => x.hash === hash);
        if (find) {
            return;
        }
        let lastOrder = Math.max.apply(null, this.vmd.map(function (item) {
            return item.order;
        }));
        lastOrder = lastOrder ? lastOrder + 1 : 1;
        this.vmd.push({
            hash: hash,
            order: lastOrder
        });
    }

    static fromJSON(json) {
        return Object.assign(new Channel(), json);
    }

}